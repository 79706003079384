<template>
  <div>
    <el-row class="mt20">
      <el-col :span="23">
        <!-- 规格设置 -->
        <h4>{{ $t('productDetail.SpecificationSetting') }}</h4>
        <el-row class="mt15">
          <!-- 开启多规格 -->
          <span class="mr10">
            {{ $t('productDetail.EnableMultipleSpecifications') }}
          </span>
          <el-switch disabled v-model="isSingleBol"></el-switch>
          <p class="mt10" v-if="!isSingleBol">
            {{ $t('productDetail.ProductCode') }}：
            {{
              productInfo.productSpecsShowVOList
                ? productInfo.productSpecsShowVOList[0].sku
                : '--'
            }}
          </p>
        </el-row>
        <el-row
          class="mt15 test"
          v-for="(item, index) of specArray"
          :key="index"
        >
          <span class="no-wrap mr10" style="color: #000">
            {{ getWholeSpecTypeName(item.type, productInfo.specsList) }}：
          </span>
          <template v-for="(tag, tagIndex) in item.value">
            <span size="medium" style="word-break: break-word">
              <!-- {{ tag }} -->
              {{ getWholeSpecValueName(item.type, tag, productInfo.specsList) }}
              <i v-show="tagIndex != item.value.length - 1">,</i>
            </span>
          </template>

          <template
            v-if="
              downShelfSpecs.length &&
              downShelfSpecs[index] &&
              downShelfSpecs[index].length &&
              !isPlatForm
            "
          >
            <span style="margin-left: 20px">
              {{ $t('productDetail.SpecDown') }}
            </span>
            <span
              v-for="(downSpec, downIndex) in downShelfSpecs[index]"
              :key="downIndex"
              size="medium"
              class="no-wrap"
            >
              {{ downSpec.nameEn }}{{ specShowSpilterator
              }}{{ downSpec.nameCn }}
              <i v-show="downIndex < downShelfSpecs[index].length - 1">,</i>
            </span>
          </template>
        </el-row>
        <el-table
          v-if="isSingleBol"
          class="mt15"
          border
          style="
            width: 70%;
            max-height: 410px;
            overflow: hidden;
            overflow-y: auto;
          "
          :data="productInfo.productSpecsShowVOList"
          :row-class-name="rowClassName"
        >
          <!-- 产品编码 -->
          <el-table-column
            prop="sku"
            :label="$t('productDetail.ProductCode')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">{{ scope.row.sku || '--' }}</template>
          </el-table-column>
          <!-- 规格图 -->
          <el-table-column
            prop="image"
            :label="$t('productDetail.SpecificationDrawing')"
            align="center"
            width="180"
          >
            <template slot-scope="scope">
              <el-row
                type="flex"
                align="middle"
                justify="center"
                v-if="scope.row.image"
              >
                <el-image
                  :src="scope.row.image"
                  class="c_pointer"
                  @click="handlePreview(scope.row.image)"
                  style="width: 50px; height: 50px"
                ></el-image>
              </el-row>
              <!-- 无规格图 -->
              <span v-else>{{ $t('other.noSpec') }}</span>
            </template>
          </el-table-column>
          <el-table-column
            width="120"
            align="center"
            v-for="(item, index) of tableSpecsType"
            :key="index"
            :label="getWholeSpecTypeName(item, productInfo.specsList)"
            :prop="'specsValue' + (index + 1)"
          >
            <template slot-scope="scope">
              {{
                getWholeSpecValueName(
                  item,
                  scope.row['specsValue' + (index + 1)],
                  productInfo.specsList
                )
              }}
            </template>
          </el-table-column>
          <!-- 状态 -->
          <el-table-column
            :label="$t('productDetail.Status')"
            prop="specsOptionStatus"
            align="center"
            width="120"
          >
            <template slot-scope="scope">
              {{
                getShelfFromDownShelfSpecs(scope.row)
                  ? $t('productDetail.Deactivated')
                  : $t('productDetail.Normal')
              }}
            </template>
          </el-table-column>
          <!-- 备注信息 -->
          <el-table-column :label="$t('productDetail.Remarks')" prop="remarks">
            <template slot-scope="scope">
              {{ scope.row.remarks || '--' }}
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <el-row class="mt20">
      <el-col :span="23">
        <!-- 产品信息 -->
        <h4>{{ $t('productDetail.ProductInformation') }}</h4>
        <p class="mt10">
          <!-- 按规格设置 -->
          <span class="mr10">{{ $t('productDetail.SATS') }}</span>
          <el-switch
            disabled
            v-model="productInfo.skuSettingForProduct ? true : false"
          ></el-switch>
        </p>
        <div v-if="productInfo.skuSettingForProduct == 1">
          <el-table
            class="mt15"
            border
            style="
              width: 90%;
              max-height: 410px;
              overflow: hidden;
              overflow-y: auto;
            "
            :data="productInfo.productSpecsShowVOList"
          >
            <!-- 产品编码 -->
            <el-table-column
              prop="sku"
              :label="$t('productDetail.ProductCode')"
              align="center"
              width="100"
            >
              <template slot-scope="scope">
                {{ scope.row.sku || '--' }}
              </template>
            </el-table-column>
            <el-table-column
              width="120"
              align="center"
              v-for="(item, index) of tableSpecsType"
              :key="index"
              :label="getWholeSpecTypeName(item, productInfo.specsList)"
              :prop="'specsValue' + (index + 1)"
            >
              <template slot-scope="scope">
                {{
                  getWholeSpecValueName(
                    item,
                    scope.row['specsValue' + (index + 1)],
                    productInfo.specsList
                  )
                }}
              </template>
            </el-table-column>
            <!-- 产品重量 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.ProductWeight')"
              width="120"
            >
              <template slot-scope="scope">
                <span class="mr5">{{ scope.row.productWeightKgs }}</span>

                <el-tooltip
                  class="item"
                  effect="light"
                  :content="
                    (scope.row.productWeightKgs * 2.2046).toFixed(1) + ' LBS'
                  "
                  placement="top"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- 包装方式 -->
            <el-table-column
              prop="packingMethodEn"
              align="center"
              :label="$t('productDetail.PackingMethod')"
            >
              <template slot-scope="scope">
                {{ scope.row.packingMethodEn || '--' }}
              </template>
            </el-table-column>
            <!-- 产品尺寸 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.ProductSize')"
              show-overflow-tooltip
              min-width="180"
            >
              <template #default="{ row }">
                <el-row v-if="row.sizeUnit === 'CM'">
                  <span>{{ row.productLengthCm || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productWidthCm || '--' }}&nbsp;
                  </span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productHeightCm || '--' }}
                  </span>
                  <span class="mr5">{{ row.sizeUnit }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <!-- IN -->
                      <p>
                        <span>
                          {{
                            (row.productLengthCm / 2.54).toFixed(2) || '--'
                          }}&nbsp;
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{ (row.productWidthCm / 2.54).toFixed(2) || '--' }}
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{ (row.productHeightCm / 2.54).toFixed(2) || '--' }}
                        </span>
                        <span>IN</span>
                      </p>
                      <!-- FT -->
                      <p class="mt5">
                        <span>
                          {{
                            (row.productLengthCm / 2.54 / 12).toFixed(2) ||
                            '--'
                          }}&nbsp;
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{
                            (row.productWidthCm / 2.54 / 12).toFixed(2) || '--'
                          }}
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{
                            (row.productHeightCm / 2.54 / 12).toFixed(2) || '--'
                          }}
                        </span>
                        <span>FT</span>
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
                <el-row v-if="row.sizeUnit === 'FT'">
                  <span>{{ row.productLengthCm || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productWidthCm || '--' }}&nbsp;
                  </span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productHeightCm || '--' }}
                  </span>
                  <span>{{ row.sizeUnit || '--' }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <span>
                        {{
                          (row.productLengthCm * 30.48).toFixed(2) || '--'
                        }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{
                          (row.productWidthCm * 30.48).toFixed(2) || '--'
                        }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{ (row.productHeightCm * 30.48).toFixed(2) || '--' }}
                      </span>
                      <span class="mr5">CM</span>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
                <el-row v-if="row.sizeUnit === 'IN' || !row.sizeUnit">
                  <span>{{ row.productLengthCm || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productWidthCm || '--' }}&nbsp;
                  </span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.productHeightCm || '--' }}
                  </span>
                  <span class="mr5">{{ row.sizeUnit || '--' }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <span>
                        {{
                          (row.productLengthCm * 2.54).toFixed(2) || '--'
                        }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{
                          (row.productWidthCm * 2.54).toFixed(2) || '--'
                        }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{ (row.productHeightCm * 2.54).toFixed(2) || '--' }}
                      </span>
                      <span class="mr5">CM</span>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
            <!-- 印刷区域尺寸 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.PrintingAreaSize')"
              min-width="120"
            >
              <template #default="{ row }">
                <el-row v-if="row.printUnit === 'CM'">
                  <span>{{ row.printLength || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.printWidth || '--' }}
                  </span>
                  <span class="mr5">{{ row.printUnit || '--' }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <!-- IN -->
                      <p>
                        <span>
                          {{
                            (row.printLength / 2.54).toFixed(2) || '--'
                          }}&nbsp;
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{ (row.printWidth / 2.54).toFixed(2) || '--' }}
                        </span>
                        <span>IN</span>
                      </p>
                      <!-- FT -->
                      <p class="mt5">
                        <span>
                          {{
                            (row.printLength / 2.54 / 12).toFixed(2) || '--'
                          }}&nbsp;
                        </span>
                        <span>
                          <span>x&nbsp;</span>
                          {{ (row.printWidth / 2.54 / 12).toFixed(2) || '--' }}
                        </span>
                        <span>FT</span>
                      </p>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
                <el-row v-if="row.printUnit === 'FT'">
                  <span>{{ row.printLength || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.printWidth || '--' }}
                  </span>
                  <span>{{ row.printUnit }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <span>
                        {{ (row.printLength * 30.48).toFixed(2) || '--' }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{ (row.printWidth * 30.48).toFixed(2) || '--' }}
                      </span>
                      <span>CM</span>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
                <el-row v-if="row.printUnit === 'IN' || !row.printUnit">
                  <span>{{ row.printLength || '--' }}&nbsp;</span>
                  <span>
                    <span>x&nbsp;</span>
                    {{ row.printWidth || '--' }}
                  </span>
                  <span class="mr5">{{ row.printUnit }}</span>
                  <el-tooltip class="item" effect="light" placement="top">
                    <div slot="content">
                      <span>
                        {{ (row.printLength * 2.54).toFixed(2) || '--' }}&nbsp;
                      </span>
                      <span>
                        <span>x&nbsp;</span>
                        {{ (row.printWidth * 2.54).toFixed(2) || '--' }}
                      </span>
                      <span>CM</span>
                    </div>
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>
                </el-row>
              </template>
            </el-table-column>
            <!-- 装箱数量 -->
            <el-table-column
              prop="numberPerBox"
              align="center"
              :label="$t('productDetail.PackingQuantity')"
              width="100"
            />
            <!-- 毛重 -->
            <el-table-column
              prop="boxWeightKgs"
              align="center"
              :label="$t('productDetail.GrossWeight')"
              width="100"
            >
              <template slot-scope="scope">
                <span class="mr5">{{ scope.row.boxWeightKgs || '--' }}</span>
                <el-tooltip
                  class="item"
                  effect="light"
                  :content="
                    (scope.row.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'
                  "
                  placement="top"
                >
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <!-- 装箱尺寸 -->
            <el-table-column
              align="center"
              :label="$t('productDetail.PackingSize')"
              width="180"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.boxLengthCm || '--' }}&nbsp;x&nbsp;</span>
                <span>{{ scope.row.boxWidthCm || '--' }}&nbsp;x&nbsp;</span>
                <span>
                  {{ scope.row.boxHeightCm || '--' }}
                </span>
                <span class="mr5">CM</span>

                <el-tooltip class="cl5" effect="light" placement="top">
                  <div slot="content">
                    <span>
                      {{ scope.row.boxLengthIn || '--' }}&nbsp;x&nbsp;
                    </span>
                    <span>{{ scope.row.boxWidthIn || '--' }}&nbsp;x&nbsp;</span>
                    <span>
                      {{ scope.row.boxHeightIn || '--' }}
                    </span>
                    <span>IN</span>
                  </div>
                  <i class="el-icon-warning-outline"></i>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="mt15" v-else>
          <el-row :gutter="20">
            <el-col :span="6">
              <!-- 包装方式 -->
              {{ $t('productDetail.PackingMethod') }}：{{
                proForm.packingMethodEn || '--'
              }}
            </el-col>
            <el-col :span="6">
              <!-- 产品重量： -->
              {{ $t('productDetail.ProductWeight') }}：{{
                proForm.productWeightKgs || '--'
              }}
              KGS
              <el-tooltip
                class="item"
                effect="light"
                :content="
                  (proForm.productWeightKgs * 2.2046 || 0).toFixed(2) + ' LBS'
                "
                placement="top"
              >
                <i class="el-icon-warning-outline cl5"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="6">
              <!-- 产品尺寸： -->
              {{ $t('productDetail.ProductSize') }}：{{
                proForm.productLengthCm || '--'
              }}
              X {{ proForm.productWidthCm || '--' }} X
              {{ proForm.productHeightCm || '--' }} {{ proForm.sizeUnit }}

              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content">
                  <!-- IN -->
                  <p v-if="proForm.sizeUnit === 'CM'">
                    <span>
                      {{
                        (proForm.productLengthCm / 2.54).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.productWidthCm / 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.productHeightCm / 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>IN</span>
                  </p>
                  <!-- FT -->
                  <p class="mt5" v-if="proForm.sizeUnit === 'CM'">
                    <span>
                      {{
                        (proForm.productLengthCm / 30.48).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.productWidthCm / 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>
                      <span>
                        <span>x&nbsp;</span>
                      </span>
                      {{ (proForm.productHeightCm / 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>FT</span>
                  </p>
                  <!-- CM -->
                  <p class="mt5" v-if="proForm.sizeUnit === 'IN'">
                    <span>
                      {{
                        (proForm.productLengthCm * 2.54).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.productWidthCm * 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>
                      <span>
                        <span>x&nbsp;</span>
                      </span>
                      {{ (proForm.productHeightCm * 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>CM</span>
                  </p>
                  <p class="mt5" v-if="proForm.sizeUnit === 'FT'">
                    <span>
                      {{
                        (proForm.productLengthCm * 30.48).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.productWidthCm * 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>
                      <span>
                        <span>x&nbsp;</span>
                      </span>
                      {{ (proForm.productHeightCm * 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>CM</span>
                  </p>
                </div>
                <i class="el-icon-warning-outline mr5"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="6">
              <!-- 印刷区域尺寸： -->
              {{ $t('productDetail.PrintingAreaSize') }}：{{
                proForm.printLength || '--'
              }}
              X {{ proForm.printWidth || '--' }} {{ proForm.printUnit }}

              <el-tooltip class="item" effect="light" placement="top">
                <div slot="content">
                  <!-- IN -->
                  <p v-if="proForm.printUnit === 'CM'">
                    <span>
                      {{
                        (proForm.printLength / 2.54).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.printWidth / 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>IN</span>
                  </p>
                  <!-- FT -->
                  <p class="mt5" v-if="proForm.printUnit === 'CM'">
                    <span>
                      {{
                        (proForm.printLength / 30.48).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.printWidth / 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>FT</span>
                  </p>
                  <!-- CM -->
                  <p v-if="proForm.printUnit === 'FT'">
                    <span>
                      {{
                        (proForm.printLength * 30.48).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.printWidth * 30.48).toFixed(2) || '--' }}
                    </span>
                    <span>CM</span>
                  </p>
                  <p v-if="proForm.printUnit === 'IN'">
                    <span>
                      {{
                        (proForm.printLength * 2.54).toFixed(2) || '--'
                      }}&nbsp;
                    </span>
                    <span>
                      <span>x&nbsp;</span>
                      {{ (proForm.printWidth * 2.54).toFixed(2) || '--' }}
                    </span>
                    <span>CM</span>
                  </p>
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mt10">
            <!-- 装箱数量： -->
            <el-col :span="6">
              {{ $t('productDetail.PackingQuantity') }}：{{
                proForm.numberPerBox
              }}
            </el-col>
            <!-- 装箱毛重： -->
            <el-col :span="6">
              {{ $t('productDetail.PackingGrossWeight') }}：{{
                proForm.boxWeightKgs
              }}
              KGS
              <el-tooltip
                class="item"
                effect="light"
                :content="(proForm.boxWeightKgs * 2.2046).toFixed(1) + ' LBS'"
                placement="top"
              >
                <i class="el-icon-warning-outline cl5"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="6">
              <!-- 装箱尺寸： -->
              {{ $t('productDetail.PackingSize') }}：{{ proForm.boxLengthCm }} X
              {{ proForm.boxWidthCm }} X {{ proForm.boxHeightCm }} CM

              <el-tooltip class="cl5" effect="light" placement="top">
                <div slot="content">
                  <span>{{ proForm.boxLengthIn || '--' }}&nbsp;x&nbsp;</span>
                  <span>{{ proForm.boxWidthIn || '--' }}&nbsp;x&nbsp;</span>
                  <span>
                    {{ proForm.boxHeightIn || '--' }}
                  </span>
                  <span>IN</span>
                </div>
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="[imgUrl]"
    />
  </div>
</template>
<script>
  import specNameMixin from '../util'
  export default {
    inject: ['specShowSpilterator'],
    mixins: [specNameMixin],
    data() {
      return {
        tableSpecsType: [],
        proForm: {},
        isSingleBol: false,
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
      }
    },
    props: {
      productInfo: {
        type: Object,
        default: () => {},
      },
      // 是否是被平台产品ProductView页面使用，如果是，需要隐藏【下架规格】，默认为false
      isPlatForm: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      downShelfSpecs: {
        get() {
          const downShelfSpecs = []
          console.log(this.productInfo)
          if (this.productInfo.isPlatFormSpec) {
            if (!this.productInfo.downShelfSpecs) return []
            return JSON.parse(this.productInfo.downShelfSpecs)
          }
          const specsList = this.productInfo.specsList
          if (specsList?.length) {
            specsList.forEach((item, index) => {
              if (!downShelfSpecs[index]) {
                downShelfSpecs[index] = []
              }
              item.children.forEach((child) => {
                if (child.status == 1) {
                  downShelfSpecs[index].push(child)
                }
              })
            })
          }
          return downShelfSpecs
        },
      },
      specArray: {
        get() {
          if (this.productInfo.specsType && this.productInfo.specsValue) {
            let typearr = this.productInfo.specsType.split(',')
            let svalue = this.productInfo.specsValue

            let valarr = JSON.parse(svalue)
            valarr = valarr.reduce(
              (previousValue, currentValue, currentIndex) => {
                let arr = []
                for (let k of currentValue) {
                  if (!this.downShelfSpecs[currentIndex]) {
                    arr.push(k)
                  } else if (
                    this.downShelfSpecs[currentIndex] &&
                    !this.downShelfSpecs[currentIndex]?.some(
                      (s) => s.nameEn === k
                    ) &&
                    !this.productInfo?.isPlatFormSpec
                  ) {
                    arr.push(k)
                  } else if (
                    // 平台产品规格接口没有返回英文你字段
                    this.productInfo?.isPlatFormSpec &&
                    this.downShelfSpecs[currentIndex] &&
                    !this.downShelfSpecs[currentIndex].includes(k)
                  ) {
                    arr.push(k)
                  }
                }
                previousValue.push(arr)
                return previousValue
              },
              []
            )
            let arr = []
            for (let i = 0; i < typearr.length; i++) {
              let obj = {}
              Object.defineProperty(obj, 'type', {
                value: typearr[i],
              })
              Object.defineProperty(obj, 'value', {
                value: valarr[i],
              })
              arr.push(obj)
            }
            return arr
          }
        },
      },
    },
    methods: {
      detailInfo(detail) {
        if (
          detail.skuSettingForProduct == 0 &&
          detail?.productSpecsShowVOList?.length > 0
        ) {
          this.proForm = detail?.productSpecsShowVOList[0]
        }
        //规格列表的规格字段
        this.tableSpecsType = []
        if (detail.specsType) {
          this.isSingleBol = true
          let specsType = detail.specsType.split(',')
          specsType.forEach((item) => {
            this.tableSpecsType.push(item)
          })
        } else {
          this.isSingleBol = false
        }
      },
      //从下架规格列表中读取状态
      getShelfFromDownShelfSpecs(row) {
        let isDown = false
        for (let j = 0; j < this.downShelfSpecs.length; j++) {
          for (let k = 0; k < this.downShelfSpecs[j].length; k++) {
            if (
              // row.specsValue1 == this.downShelfSpecs[j][k] ||
              // row.specsValue2 == this.downShelfSpecs[j][k]
              row.specsValue1 == this.downShelfSpecs[j][k]?.nameEn ||
              row.specsValue2 == this.downShelfSpecs[j][k]?.nameEn
            ) {
              isDown = true
              return isDown
            }
          }
        }
      },
      //根据规格列表中状态为1的列让该行变为灰色
      rowClassName({ row }) {
        let flag = this.getShelfFromDownShelfSpecs(row)
        if (flag) {
          // 变颜色的条件
          return 'rowName' // 这个return的就是样式 可以是color 也可以是backgroundColor
        }
      },
      // 大图预览
      handlePreview(item) {
        this.imgUrl = item
        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },
    },
  }
</script>
<style>
  .rowName {
    background-color: #f5f7fa !important ;
  }
</style>
