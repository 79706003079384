export default {
  methods: {
    // 根据英文规格类型英文名获取规格类型中文名
    getWholeSpecTypeName(nameEn, specsList) {


      specsList = specsList || []
      const targetSpec = specsList.find((item) => item.nameEn === nameEn)
      if(targetSpec) {
        return `${targetSpec.nameEn}${this.specShowSpilterator}${targetSpec.nameCn}`
      }
      return nameEn
    },

    // 根据英文规格类型英文名和规格值类型英文名获取规格类型中文名
    getWholeSpecValueName(specTypeEn, specValueEn, specsList) {
      specsList = specsList || []
      const targetSpec = specsList.find((item) => item.nameEn === specTypeEn)
      if(targetSpec) {
        // 根据specValueEn从children中获取中文名
        const targetValue = targetSpec.children?.find((item) => item.nameEn === specValueEn)
        if(targetValue) {
          return `${targetValue.nameEn}${this.specShowSpilterator}${targetValue.nameCn}`
        }
      }
      return specValueEn
    },

  },
}
